import axios from '@/axios.js'

export default {
  markReadAlert ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/alert/read/${payload.id}`)
        .then((response) => {
          commit('MARK_READ_ALERT', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchUnreadAlerts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/alerts`)
        .then((response) => {
          commit('SET_UNREAD_ALERTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/alerts`, payload)
        .then((response) => {
          commit('SET_ALERTS_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
