<template>
  <div v-if="params.data" class="flex items-center cursor-pointer">
    <feather-icon v-if="!params.data.pivot || !params.data.pivot.read" title="Mensagem não lida." icon="AlertCircleIcon" svgClasses="h-4 w-4" class="ml-1 mr-1 text-danger" />
    <span>{{ params.data.title }}</span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink'
}
</script>
