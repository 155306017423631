var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "alerts" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popupAlert",
          attrs: { title: _vm.alert.title, active: _vm.popupAlert },
          on: {
            "update:active": function ($event) {
              _vm.popupAlert = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/5" },
              [
                _c("feather-icon", {
                  staticClass: "ml-4",
                  attrs: { icon: _vm.alert.icon, size: "large" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-4/5" },
              [
                _c("span", {
                  staticClass: "text-sm",
                  domProps: { innerHTML: _vm._s(_vm.alert.message) },
                }),
                _vm._t("default"),
              ],
              2
            ),
          ]),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupAlert = false
                        },
                      },
                    },
                    [_vm._v("OK")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "vx-card p-6" },
        [
          _c("div", { staticClass: "flex flex-wrap items-center" }, [
            _c(
              "div",
              { staticClass: "flex-grow" },
              [
                _c(
                  "vs-dropdown",
                  {
                    staticClass: "cursor-pointer",
                    attrs: { "vs-trigger-click": "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                      },
                      [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentPage * _vm.paginationPageSize -
                                (_vm.paginationPageSize - 1)
                            ) +
                              " - " +
                              _vm._s(
                                _vm.allData.total -
                                  _vm.currentPage * _vm.paginationPageSize >
                                  0
                                  ? _vm.currentPage * _vm.paginationPageSize
                                  : _vm.allData.total
                              ) +
                              " de " +
                              _vm._s(_vm.allData.total)
                          ),
                        ]),
                        _c("feather-icon", {
                          attrs: {
                            icon: "ChevronDownIcon",
                            svgClasses: "h-4 w-4",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-dropdown-menu",
                      [
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.paginationSetPageSize(10)
                              },
                            },
                          },
                          [_c("span", [_vm._v("10")])]
                        ),
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.paginationSetPageSize(20)
                              },
                            },
                          },
                          [_c("span", [_vm._v("20")])]
                        ),
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.paginationSetPageSize(30)
                              },
                            },
                          },
                          [_c("span", [_vm._v("30")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              components: _vm.components,
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              overlayNoRowsTemplate: _vm.noRowsTemplate,
              localeText: _vm.localeText,
              rowSelection: "multiple",
              colResizeDefault: "shift",
              animateRows: true,
              pagination: true,
              paginationPageSize: _vm.paginationPageSize,
              cacheBlockSize: _vm.payload.limit,
              suppressPaginationPanel: true,
              enableRtl: _vm.$vs.rtl,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
          _c("vs-pagination", {
            attrs: { total: _vm.totalPages, max: 10 },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }