import Vue from 'vue'

export default {
  SET (state, payload) {
    state.alert = Object.assign({}, state.alert, payload) //precisa ser assim para manter a reatividade
  },
  SET_UNREAD_ALERTS (state, payload) {
    state.unreadAlerts = payload.unreadAlerts
    state.unreadAlertsTotal = payload.unreadAlertsTotal
  },
  INCOMING_ALERT (state, payload) {
    const idx = payload.to_users.findIndex(a => a.id === payload.user.id)
    if (idx >= 0) {
      payload.pivot = {read: 0} // necessário
      state.unreadAlerts.unshift(payload)
      state.unreadAlertsTotal++

      state.alertsGrid.data.unshift(payload)
      state.alertsGrid.total++
    }
  },
  MARK_READ_ALERT (state, payload) {
    const idx = state.unreadAlerts.findIndex(u => u.id === payload.id)
    if (idx >= 0) {
      state.unreadAlerts.splice(idx, 1)
      state.unreadAlertsTotal--
    }

    const idx2 = state.alertsGrid.data.findIndex(u => u.id === payload.id)
    if (idx2 >= 0) {
      const p = state.alertsGrid.data[idx2]
      if (p.pivot) p.pivot.read = 1
      Vue.set(state.alertsGrid.data, [idx2], p)
    }

  },
  REFRESH (state, payload) {
    state.alerts = payload
  },
  SET_ALERTS_GRID (state, payload) {
    state.alertsGrid = payload
  }
}
