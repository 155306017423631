<template>
  <div id="alerts">
    <vs-popup class="popupAlert" :title="alert.title" :active.sync="popupAlert">
      <div class="vx-row">
        <div class="vx-col w-1/5">

          <feather-icon :icon="alert.icon" size="large" class="ml-4" />

        </div>
        <div class="vx-col w-4/5">
          <span class="text-sm" v-html="alert.message"></span>
          <slot />
        </div>
      </div>
      <hr>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupAlert = false">OK</vs-button>
            <!-- <vs-button class="ml-4" :color="actionButtonColor" @click="action">{{ actionButtonText }}</vs-button> -->
          </div>
        </div>
      </div>
    </vs-popup>

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'

// Cell Renderer
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'
import CellRendererAlert from './cell-renderer/CellRendererAlert.vue'

import moduleAlert        from '@/store/alert/moduleAlert.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererDateTime,
    CellRendererAlert
  },
  data () {
    return {
      popupAlert: false,
      alert: {
        title: '',
        message: '',
        icon: ''
      },

      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Data',
          field: 'created_at',
          width: 270,
          floatingFilter:true,
          filter: 'agDateColumnFilter',
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['equals', 'inRange'],
            defaultOption: 'equals',
            debounceMs: 800,
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue
              if (dateAsString === null) return -1
              const dateParts = dateAsString.split('/')
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              )

              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1
              }
            },
            browserDatePicker: true,
            minValidYear: 2000
          },
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Alerta',
          field: 'alert',
          width: 500,
          sortable: false,
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
          onCellClicked: event => { this.showAlert(event.data) },
          cellRendererFramework: 'CellRendererAlert'
        }
      ],
      components: {
        CellRendererDateTime,
        CellRendererAlert
      }
    }
  },
  computed: {
    unreadAlertsTotal () { return this.$store.state.alert.unreadAlertsTotal },
    unreadAlerts () { return this.$store.state.alert.unreadAlerts },
    user () {
      return this.$store.state.AppActiveUser
    },
    allData () {
      return this.$store.state.alert.alertsGrid
    },
  },
  methods: {
    showAlert (data) {
      this.alert = data
      this.popupAlert = true
      if (!data.pivot.read) this.read(data.id)
    },
    async read (id) {
      await this.$store.dispatch('alert/markReadAlert', { id, user: this.user })
      if (!this.unreadAlerts.length && this.unreadAlertsTotal > 0) this.$store.dispatch('alert/fetchUnreadAlerts')
    },

    refreshGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
      // this.gridApi.refreshInfiniteCache() // REMOVI ESSE PQ QDO NÃO HÁ NENHUM REGISTRO NÃO ATUALIZA AO CADASTRAR O PRIMEIRO
    },
    refreshInfiniteCacheGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },

    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('alert/fetchGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    /** AQUI ESCUTO OS EVENTOS DE CHAT DO LARAVEL */
    window.Echo.channel('public').listen('.alert', () => {
      this.refreshGrid()
    })

    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    if (!moduleAlert.isRegistered) {
      this.$store.registerModule('alert', moduleAlert)
      moduleAlert.isRegistered = true
    }
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  }
}
</script>

<style>
.popupAlert .vs-popup {
  width: 55% !important;
  border-color: #FF9F43 !important;
  border: solid;
  border-width: thin;
}
.popupAlert .vs-popup--header {
  border-color: #FF9F43 !important;
  border: solid;
  border-width: thin;
}
</style>
