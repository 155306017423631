var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c(
        "div",
        { staticClass: "flex items-center cursor-pointer" },
        [
          !_vm.params.data.pivot || !_vm.params.data.pivot.read
            ? _c("feather-icon", {
                staticClass: "ml-1 mr-1 text-danger",
                attrs: {
                  title: "Mensagem não lida.",
                  icon: "AlertCircleIcon",
                  svgClasses: "h-4 w-4",
                },
              })
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.params.data.title))]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }