import state from './moduleAlertState.js'
import mutations from './moduleAlertMutations.js'
import actions from './moduleAlertActions.js'
import getters from './moduleAlertGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

